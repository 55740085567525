import { HTTPClient } from "../http-common";
import { Product } from "../models/product";

export async function createProduct(product: Product): Promise<Product> {
    let data = {
        "brand": product.brand,
        "model": product.model,
        "sku": product.sku,
        "title": product.title,
        "details": product.details
    }
    return await HTTPClient.post('product', data);
}