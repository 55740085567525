
import { getProduct } from '@/http/methods/get-product';
import { getProducts } from '@/http/methods/get-products';
import { Product } from '@/http/models/product';
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            products: {} as Product[]
        }
    },
    methods: {
        async fetchProduct() {
            this.products = await getProducts();
        }
    },
    mounted() {
        this.fetchProduct();
    },
});

