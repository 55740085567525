import { createApp } from 'vue'
import App from './App.vue'
import { router } from "./router"
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Tree from 'primevue/tree';
import ToastService from 'primevue/toastservice';
import Toast from "primevue/toast";

import "primevue/resources/themes/soho-light/theme.css";

// import 'primeflex/primefliex.css';
import './assets/main.css';
import InputText from 'primevue/inputtext';

const app = createApp(App)

app.use(router)
app.use(PrimeVue)
app.use(ToastService)

app.component('Button', Button)
app.component('Dialog', Dialog)
app.component('InputText', InputText)
app.component('Tree', Tree)
app.component("Toast", Toast)


app.mount('#app')
