import * as Parse from 'parse';
import { Category } from "../models/category";

export async function getCategories(): Promise<Category[]> {
    const Category = Parse.Object.extend("Category");
    const query = new Parse.Query(Category);
    let res = await query.findAll()
    return res.map(a => { return {
        id: a.id, 
        name: a.get("name"),
        parent: a.get("parent")
    }})
}