
import { createProduct } from '@/http/methods/create-product';
import { TreeNode, TreeSelectionKeys } from 'primevue/tree';
import { PropType, defineComponent } from 'vue';
import { useToast } from 'primevue/usetoast';



export default defineComponent({
    props: {

    },
    data() {
        return {
            brand: "",
            model: "",
            sku: "",
            title: "",
            confirmVisible: false
        }
    },
    methods: {
        async create() {
            await createProduct({
                id: "",
                brand: this.brand,
                model: this.model,
                sku: this.sku,
                title: this.title,
                details: [
                    { key: "1", value: "1" }
                ]
            })
            this.confirmVisible = true
            // const toast = useToast();
            // this.$toast.add({
            //     severity: "success",
            //     summary: "Товар создан",
            //     detail: 'Message content',
            //     life: 3000,
            //     group: 'bl'
            // })
        },
        doneAlert() {
            location.reload();
        }
    },
    mounted() {

    },
});

