
import { createCategory } from '@/http/methods/create-category';
import { getCategories } from '@/http/methods/get-categories';
import { Category } from '@/http/models/category';
import { TreeNode, TreeSelectionKeys } from 'primevue/tree';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        categoryIds: Object as PropType<TreeSelectionKeys | undefined>
    },
    data() {
        return {
            name: "",
            visible: false,
            categories: [] as TreeNode[],
            categoryIds: {} as TreeSelectionKeys
        }
    },
    methods: {
        async create() {
            let categoryId = Object.keys(this.categoryIds)[0]
            await createCategory(this.name, categoryId)
            this.visible = true
            this.fetchCategorie()
        },
        async fetchCategorie() {
            let categories = await getCategories()
            this.categories = this.mapCategories(categories, undefined)
        },
        mapCategories(categories: Category[], parentId: String | undefined): TreeNode[] {
            return categories.reduce((res, category) => {
                if (category.parent?.id != parentId) {
                    return res
                }
                let node = <TreeNode> {
                    key: category.id,
                    label: category.name,
                    children: this.mapCategories(categories, category.id)
                }
                return res.concat(node)
            }, [] as TreeNode[])
        }
    },
    mounted() {
        this.fetchCategorie();
    },
});

