import axios from "axios";

import * as Parse from 'parse';
let parse = require('parse');

class Client {

    constructor() {
        Parse.initialize("wiki");
        parse.serverURL = "https://api.prostokatalog.ru/";
    }

    private client = axios.create({
        baseURL: "http://188.225.73.155:8081/",
        headers: {
            "Content-Type": "application/json",
        }
    });

    async get<T>(url: string): Promise<T> {
        const response = await this.client.get<T>(url);
        return response.data;
    }

    async post<A, T>(url: string, data: A): Promise<T> {
        const response = await this.client.post<T>(url, data);
        return response.data;
    }
}

export const HTTPClient = new Client()