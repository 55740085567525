import { createRouter, createWebHistory } from 'vue-router'
import ProductVue from "./components/ProductVue.vue"
import EditCategoryForm from "./components/EditCategoryForm.vue"
import EditProductVue from './components/EditProduct.vue';

const routes = [
  { path: "/", component: ProductVue },
  { path: "/category/new", component: EditCategoryForm },
  { path: "/product/new", component: EditProductVue }
]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
});