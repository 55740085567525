import { Product } from "../models/product";
import * as Parse from 'parse';

export async function getProducts(): Promise<Product[]> {
    const Product = Parse.Object.extend("Product");
    const query = new Parse.Query(Product);
    let res = await query.findAll()
    return res.map(a => { return {
        id: a.id,
        brand: a.get("brand"),
        model: a.get("model"),
        sku: a.get("sku"),
        title: a.get("title"),
        details: []
    }})
}