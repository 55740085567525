import { Category } from "../models/category";
import * as Parse from 'parse';

export async function createCategory(name: String, parentId: String | undefined): Promise<void> {
    const Category = Parse.Object.extend("Category");
    const object = new Category();
    const parent = new Category();
    parent.id = parentId

    object.add({
        "name": name,
        "parent": parent
    });
    return await object.save();
}