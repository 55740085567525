import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-column overflow-hidden" }
const _hoisted_2 = { class: "m-5 center" }
const _hoisted_3 = { class: "card-container" }
const _hoisted_4 = { class: "inline-block p-4" }
const _hoisted_5 = { class: "inline-block p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, { to: "/category/new" }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, { label: "Создать категорию" })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, { to: "/product/new" }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, { label: "Создать товар" })
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}